import { cloneDeep, intersectionWith, isEqual } from 'lodash'
import jQuery from 'jquery'

const ProgressBar  = require('progressbar.js')

// This function will attach the keypress of "enter" to trigger the element's click event
// This function is only needed on 'non-native' clickable elements like div / span ....
// Things like <a> <input> <button> will not this function
export function attachEnterAsClick($element) {
    if (!$element.length) {
        return
    }

    $element.each(function () {
        jQuery(this).on('keyproess', function (e) {
            // Enter key pressed
            if (e.which === 13) {
                // Trigger click event on the same element
                jQuery(this).trigger('click')
            }
        })
    })
}

export function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
    const results = regex.exec(window.location.search)
    if (results !== null && results[1] === 'true') return true
    return results === null ? '' : parseInt(decodeURIComponent(results[1].replace(/\+/g, ' ')))
}

// Simple function to check if the passed in value is null or undefined
export function isNullOrUndefined(value, rejectBlankString = false) {
    if (value === null) {
        return true
    }

    if (typeof (value) === 'undefined') {
        return true
    }

    if (rejectBlankString && value === '') {
        return true
    }

    return false
}

// Function to check if the passed in number odd or not
export function isOdd(num) {
    if (num === 0) return false

    return (num & -num) === 1
}

// Function to toggle value in / out of an array (object is not supported)
export function toggleArrayItem(valueArray, value) {
    const returnArray = cloneDeep(valueArray)
    const i = returnArray.indexOf(value)
    if (i === -1) {
        returnArray.push(value)
    } else {
        returnArray.splice(i, 1)
    }
    return returnArray
}

// Use ProgressBar.js library to draw the chassis percentage https://progressbarjs.readthedocs.io/en/latest/api/shape/
export function drawPercentProgressBars(fillQuoteRequirementError) {
    jQuery('.progress-bar-percent').each((index, element) => {
        const $progressBarElement = jQuery(element)
        const percentage = element.dataset.value

        $progressBarElement.empty()

        const progressBarConfiguration = {
            strokeWidth: 25,
            trailColor: '#f4f4f4',
            trailWidth: 8,
            text: {
                value: percentage + '%'
            },
            step: function(state, circle) {
                if (fillQuoteRequirementError) {
                    circle.path.setAttribute('stroke', '#E74C3C')
                } else {
                    circle.path.setAttribute('stroke', '#3DCD58')
                }

                circle.path.setAttribute('stroke-width', 8)
                var value = Math.round(circle.value() * 100)
                circle.setText(value + '%')
            }
        }

        if (fillQuoteRequirementError) {
            progressBarConfiguration.color = '#E74C3C'
        }

        const circle = new ProgressBar.Circle(element, progressBarConfiguration)

        if (window.progressCircles === undefined) {
            window.progressCircles = []
        } 

        window.progressCircles.push(circle)

        circle.animate(percentage / 100)
    })
}

export function getNumberOfRowsFromPolesString(polesString) {
    if (polesString === '8' || polesString === '11' || polesString === '12' || polesString === '15' || polesString === '17' || polesString === '18') {
        return 1
    } else if (polesString === '12x2' || polesString === '15x2') {
        return 2
    } else if (polesString === '12x3' || polesString === '15x3') {
        return 3
    } else if (polesString === '15x4') {
        return 4
    }
}

// Possible example values: "5", "12", "17+4", "2+10"
export function getNumberOfPolesFromStringExpression(numberOfPolesStringExpression) {
    const indexOfPlusSign = numberOfPolesStringExpression.indexOf('+')
    if (indexOfPlusSign === -1) return parseInt(numberOfPolesStringExpression, 10)

    const firstNumber = numberOfPolesStringExpression.substr(0, indexOfPlusSign) 
    const secondNumber = numberOfPolesStringExpression.substr(indexOfPlusSign + 1)

    return parseInt(firstNumber, 10) + parseInt(secondNumber, 10)
}

export function getPolesCountFromPolesString(polesString) {
    if (polesString === '12x2' || polesString === '12x3') return 12
    else if (polesString === '15x2' || polesString === '15x3') return 15
    else if (polesString === '15x4') return 15
    else return parseInt(polesString, 10)
}

export function canAddDeviceToBoardPosition(speceUsed, rowIndex, poleIndex, returnErrorMessage, board, boardDetails, boardOptionData, isMainSwitch, breakerItemID, boardContactorId, boardFittedOptionId, isWiserEnergyGateWay, ignoreBusbarChecks) {
    const rowPoles = getPolesCountFromPolesString(boardDetails.PolesNeeded)
    const poleIndexesToUse = [poleIndex]
    const speceUsedInteger = parseInt(speceUsed, 10) 
    for (let index = 1; index < speceUsedInteger; index++) {
        poleIndexesToUse.push(poleIndex + index)
    }

    let anyIssues = false
    let errorMessage = 'There is not enough space to add the device to that position!'

    // If a row has a busbar then don't allow to add it on the row
    const rowBusbar = board.ProjectBoardBusbarCollection.find(busbar => busbar.Row === rowIndex + 1)
    // For now ignore if it's a Wiser Energy Gateway, ideally we will check if a device goes on a position not covered 
    // by the busbar and allow that, or if it's compatible device that can be added to the busbar
    if (rowBusbar && !isWiserEnergyGateWay && !boardContactorId && !breakerItemID && !boardFittedOptionId && (ignoreBusbarChecks === undefined || ignoreBusbarChecks === false)) { 
        anyIssues = true
        errorMessage = 'Can\'t add a device to a row with a busbar, please remove the busbar first!'
    }

    // Allow any breakers to be added to space not covered by busbars (incl. space for future upgrades).
    if (rowBusbar && !!breakerItemID) {
        // Find all the breakers under the busbar
        const poleIndexesUnderTheBusbar = [rowBusbar.StartPosition - 1]
        for (let index = rowBusbar.StartPosition; index < rowBusbar.StartPosition + rowBusbar.BusBarLength + rowBusbar.ExtraPoles - 1; index++) {
            poleIndexesUnderTheBusbar.push(index)
        }
        const breakersUnderBusbar = board.ProjectBoardBreakerCollection.filter(breaker => breaker.RowIndex === rowIndex && poleIndexesUnderTheBusbar.indexOf(breaker.PoleIndex) > -1)
        // Find breaker under the busbar with the highest PoleIndex, as it's the last element
        const lastBreakerUnderBusbar = breakersUnderBusbar.find(breaker => breaker.PoleIndex === Math.max(...breakersUnderBusbar.map(breaker => breaker.PoleIndex)))
        
        // Consider any extra poles as well, breakers can't go under the busbar
        if (poleIndex <= lastBreakerUnderBusbar.PoleIndex + rowBusbar.ExtraPoles) {
            anyIssues = true
            errorMessage = 'Can\'t add the breaker under the busbar, please remove the busbar first!'
        }
    }
    
    // Allow contactors to be added to space not covered by busbars (incl. space for future upgrades).
    if (rowBusbar && !!boardContactorId) {
        // Find all the breakers under the busbar
        const poleIndexesUnderTheBusbar = [rowBusbar.StartPosition - 1]
        for (let index = rowBusbar.StartPosition; index < rowBusbar.StartPosition + rowBusbar.BusBarLength + rowBusbar.ExtraPoles - 1; index++) {
            poleIndexesUnderTheBusbar.push(index)
        }
        const breakersUnderBusbar = board.ProjectBoardBreakerCollection.filter(breaker => breaker.RowIndex === rowIndex && poleIndexesUnderTheBusbar.indexOf(breaker.PoleIndex) > -1)
        // Find breaker under the busbar with the highest PoleIndex
        const lastBreakerUnderBusbar = breakersUnderBusbar.find(breaker => breaker.PoleIndex === Math.max(...breakersUnderBusbar.map(breaker => breaker.PoleIndex)))
        
        // Consider any extra poles as well, contactors can't go under the busbar
        if (poleIndex <= lastBreakerUnderBusbar.PoleIndex + rowBusbar.ExtraPoles) {
            anyIssues = true
            errorMessage = 'Can\'t add the contactor under the busbar!'
        }
    }

    // Allow fitted options to be added to space not covered by busbars (incl. space for future upgrades).
    if (rowBusbar && !!boardFittedOptionId) {
        // Find all the breakers under the busbar
        const poleIndexesUnderTheBusbar = [rowBusbar.StartPosition - 1]
        for (let index = rowBusbar.StartPosition; index < rowBusbar.StartPosition + rowBusbar.BusBarLength + rowBusbar.ExtraPoles - 1; index++) {
            poleIndexesUnderTheBusbar.push(index)
        }
        const breakersUnderBusbar = board.ProjectBoardBreakerCollection.filter(breaker => breaker.RowIndex === rowIndex && poleIndexesUnderTheBusbar.indexOf(breaker.PoleIndex) > -1)
        // Find breaker under the busbar with the highest PoleIndex
        const lastBreakerUnderBusbar = breakersUnderBusbar.find(breaker => breaker.PoleIndex === Math.max(...breakersUnderBusbar.map(breaker => breaker.PoleIndex)))

        // Consider any extra poles as well, fitted options can't go under the busbar
        if (poleIndex <= lastBreakerUnderBusbar.PoleIndex + rowBusbar.ExtraPoles) {
            anyIssues = true
            errorMessage = 'Can\'t add the fitted option under the busbar!'
        }
    }

    // Surge protection always goes on first row in the first pole position, if it is there then it's not possible to add anything there
    if (rowIndex === 0 && boardDetails.SurgeProtection) {
        const mainSwitchDetails = boardOptionData.MainSwitches.find(mainSwitch => mainSwitch.ItemID === board.MainSwitchID)
        let positionsOccupiedBySurgeProtection = []
        // Surge protection is always on the first row and always starts from the first pole, but the size of it is based on the size of the main switch
        for (let index = 0; index < mainSwitchDetails.SpaceUsed; index++) {
            positionsOccupiedBySurgeProtection.push(index)    
        }

        let intersectingElements = intersectionWith(poleIndexesToUse, positionsOccupiedBySurgeProtection, isEqual)
        if (intersectingElements.length > 0) anyIssues = true
    }

    // Main switch always goes on the first row, but can be moved to any position
    if (rowIndex === 0) {
        // And if the main switch will be in any potential positions of the device, then it can't be placed there
        const mainSwitchPosition = parseInt(board.MainSwitchPosition, 10)
        const mainSwitchDetails = boardOptionData.MainSwitches.find(mainSwitch => mainSwitch.ItemID === board.MainSwitchID)
        let positionsOccupiedByMainSwitch = []
        for (let index = mainSwitchPosition; index < mainSwitchPosition + mainSwitchDetails.SpaceUsed; index++) {
            positionsOccupiedByMainSwitch.push(index)    
        }

        let intersectingElements = intersectionWith(poleIndexesToUse, positionsOccupiedByMainSwitch, isEqual)
        if (intersectingElements.length > 0 && !isMainSwitch) anyIssues = true
    }

    // Check breaker devices
    board.ProjectBoardBreakerCollection.forEach(breaker => {
        if (breakerItemID === undefined || (breakerItemID !== undefined && breaker.ItemID !== breakerItemID)) {
            let positionsOccupiedByExistingDevice = []
            const spaceUsedByExistingDevice = parseInt(breaker.BreakerTypeReadOnly.SpaceUsed, 10)
            for (let index = breaker.PoleIndex; index < breaker.PoleIndex + spaceUsedByExistingDevice; index++) {
                positionsOccupiedByExistingDevice.push(index)    
            }
            
            let intersectingElements = intersectionWith(poleIndexesToUse, positionsOccupiedByExistingDevice, isEqual)
            if (breaker.RowIndex === rowIndex && intersectingElements.length > 0) {
                anyIssues = true
            }
        }
    })

    // Check contactors
    board.ProjectBoardContractorCollection.forEach(contactor => {
        if (boardContactorId === undefined || (boardContactorId !== undefined && contactor.ItemID !== boardContactorId)) {
            let positionsOccupiedByExistingDevice = []
            const spaceUsedByExistingDevice = parseInt(contactor.ContractorReadOnly.SpaceUsed, 10)
            for (let index = contactor.PoleIndex; index < contactor.PoleIndex + spaceUsedByExistingDevice; index++) {
                positionsOccupiedByExistingDevice.push(index)    
            }

            const intersectingElements = intersectionWith(poleIndexesToUse, positionsOccupiedByExistingDevice, isEqual)
            if (contactor.RowIndex === rowIndex && intersectingElements.length > 0) anyIssues = true
        }
    })

    // Check fitted options
    board.ProjectBoardFittedOptionCollection.forEach(fittedOption => {
        if (boardFittedOptionId === undefined || (boardFittedOptionId !== undefined && fittedOption.ItemID !== boardFittedOptionId)) {
            let positionsOccupiedByExistingDevice = []
            const spaceUsedByExistingDevice = parseInt(fittedOption.FittedOptionReadOnly.SpaceUsed, 10)
            for (let index = fittedOption.PoleIndex; index < fittedOption.PoleIndex + spaceUsedByExistingDevice; index++) {
                positionsOccupiedByExistingDevice.push(index)    
            }

            const intersectingElements = intersectionWith(poleIndexesToUse, positionsOccupiedByExistingDevice, isEqual)
            if (fittedOption.RowIndex === rowIndex && intersectingElements.length > 0) anyIssues = true
        }
    })

    if (poleIndex + speceUsedInteger > rowPoles) {
        anyIssues = true
    }

    if (!!returnErrorMessage) {
        return anyIssues ? errorMessage : ''
    } else {
        console.log('anyIssues', anyIssues)
        return !anyIssues
    }
}

export function validateProjectOrBoardName(name) {
    const regex = /^[a-zA-Z0-9- ]*$/
    return regex.test(name)
}